import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AmazeeIOButton } from "../../components/button/button";
import './ai-form.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';

const sanitizeInput = (input) => {
  const tempElement = document.createElement("div");
  tempElement.textContent = input;
  return tempElement.innerHTML;
};

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const specialParams = ["fg", "aiostaff"];
  for (let param of specialParams) {
    if (params.has(param)) {
      return { key: param, value: params.get(param) };
    }
  }
  return null;
};

// @bryan set values here
const appOptions = {
  "5422d428-0bbf-46ce-a688-83d35936f4a9": [
    // { name: "amazee.io Free Trial - Basic Drupal CMS + AI", value: "0b7335de-77e3-403d-81c7-20c614c5dec4" },
    { name: "amazee.io Free Trial - Content Categorization", value: "e670d19c-2ef0-4fa3-b4d8-d218852ce335" },
    { name: "amazee.io Free Trial - CK Editor", value: "0be568df-3855-47f2-b145-0283bb6170db" },
    // { name: "amazee.io Free Trial - AI Search", value: "579d53f9-3376-4ecc-8432-81d28890dbdf" },
  ],
  "4412071a-d7b5-4aef-8bde-beea6892b710": [
    // { name: "amazee.io Free Trial - Basic Drupal CMS + AI", value: "1e03720c-c331-4ecd-8116-917c72a3d164" },
    { name: "amazee.io Free Trial - Content Categorization", value: "65e23e0b-e018-465d-8394-3b1667b14b4a" },
    { name: "amazee.io Free Trial - CK Editor", value: "5735bb34-e386-4135-99cd-adb5dd736a54" },
    // { name: "amazee.io Free Trial - AI Search", value: "1a496e5c-65f2-43a2-b208-5bc86d06028d" },
  ],
  "80979949-6ed5-4c0e-86f3-123b5bffae95": [
    // { name: "amazee.io Free Trial - Basic Drupal CMS + AI", value: "369a921d-06eb-48a1-8cdf-25fa8b4613af" },
    { name: "amazee.io Free Trial - Content Categorization", value: "e0fd318e-3589-4b0e-851e-b02c2428fd49" },
    { name: "amazee.io Free Trial - CK Editor ", value: "71f2d0a7-7947-4931-a425-5c0fc69b7ac5" },
    // { name: "amazee.io Free Trial - AI Search", value: "13d25fc0-16e1-4eb9-9b21-b89581e321e4" },
  ],
  "6e46ff17-301b-4444-8780-2990e1ddfa49": [
    // { name: "amazee.io Free Trial - Basic Drupal CMS + AI", value: "86013e97-7448-4b39-920d-ccd2a97883ec" },
    { name: "amazee.io Free Trial - Content Categorization", value: "c1583ce0-b5db-466e-8df5-8d3b92e97295" },
    { name: "amazee.io Free Trial - CK Editor ", value: "63ebd1c4-e8ca-4507-98af-9b755912f523" },
    // { name: "amazee.io Free Trial - AI Search", value: "798e626d-43d7-4e28-8b87-e1f996cc1c35" },
  ],
};

const specialMessages = {
  fg: `
    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-fg-demo1.de3.amazee.io' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
  `,
  aiostaff: {
    1: `
        <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-aiostaff-demo1.de3.amazee.io/' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
        `,
    2: `
    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-aiostaff-demo2.de3.amazee.io/' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
        `,
    3: `
    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-aiostaff-demo3.de3.amazee.io/' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
        `,
    4: `
    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-aiostaff-demo4.de3.amazee.io/' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
        `,
    5: `
    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
    <div>
      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
    </div>
    <div>
      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
    </div>
      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='https://nginx.main.aitrial-aiostaff-demo5.de3.amazee.io/' target='_blank'>Log In Now</a></p>
    <div>
      <ul class="mb-6 bottom-callouts-ul">
        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
        <li class="need-help"><i></i>
          <span><strong>Need some help?</strong> <br />
          Our friendly support team is standing by at: <br />
          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
        </li>
      </ul>
      <h4>Welcome aboard!</h4>
    </div>
    `
  }
};

const SignupForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dataRegion: "",
    selectedApp: "",
    agreeToPolicy: 0,
    agreeToMarketing: 0,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [specialMessage, setSpecialMessage] = useState(null);

  useEffect(() => {
    const paramData = getQueryParams();
    if (paramData) {
      const { key, value } = paramData;
      if (key === "fg" && specialMessages.fg) {
        setSpecialMessage(specialMessages.fg);
      } else if (key === "aiostaff" && specialMessages.aiostaff[value]) {
        setSpecialMessage(specialMessages.aiostaff[value]);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : sanitizeInput(value),
      ...(name === "dataRegion" ? { selectedApp: "" } : {}),
    });
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.dataRegion) {
      setResponseMessage("Please select a Data Region.");
      return;
    }

    if (formData.agreeToPolicy !== 1) {
      setResponseMessage("You must agree to the Acceptable Use Policy and Privacy Policy.");
      return;
    }

    if (!recaptchaToken) {
      setResponseMessage("Please verify that you are not a robot.");
      return;
    }

    const postData = {
      email: sanitizeInput(formData.email),
      first_name: sanitizeInput(formData.firstName),
      last_name: sanitizeInput(formData.lastName),
      register_type: "REQUEST_TRIAL",
      aup_and_privacy_acceptance: formData.agreeToPolicy,
      opt_in_to_product_updates: formData.agreeToMarketing,
      recaptcha: recaptchaToken,
      trial_app: formData.selectedApp
    };

    setIsLoading(true);
    setIsFormVisible(false);

    try {
      const postResponse = await fetch("https://nginx.edition-atlanta.aitrial-polydock-engine-atlanta.ch4.amazee.io/api/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      });

      if (!postResponse.ok) throw new Error("Failed to submit form");

      const { status, message, id } = await postResponse.json();
      if (status === "pending") {
        setResponseMessage(`
          <div class="loader flex justify-center pt-10 gap-3"><div class="spinner"></div></div>
          <div class="center">
            <h3>CREATING YOUR AI PLAYGROUND!</h3>
            <p>Your trial environment is being built right now! This typically takes 2-3 minutes.</p>
            <p>You'll receive an email confirmation once everything is ready. We can't wait for you to experience what's possible!</p>
          </div>
        `);
        
        console.log(status);
        
        const getUrl = `https://nginx.edition-atlanta.aitrial-polydock-engine-atlanta.ch4.amazee.io/api/register/${id}`;
        const pollInterval = 5000;
        
        console.log('UUID' + id);
        
        const startTime = Date.now();
        const timeout = 420000; // 7 minutes in milliseconds
        console.log(startTime);

        const poll = async () => {

          const checkStatus = async () => {
            try {
              const getResponse = await fetch(getUrl);
              if (!getResponse.ok) throw new Error("Failed to retrieve response");

              const finalData = await getResponse.json();
              const finalStatus = finalData.status;

              if (finalStatus === "success" || finalStatus === "failed") {
                setIsLoading(false);
                setIsFormVisible(false);

                if (specialMessage) {
                  setResponseMessage(specialMessage);
                } else if (finalStatus === "success" && finalData.result_data && finalData.result_data.app_url) {
                  console.log(finalData.result_data);
                  setResponseMessage(`
                    <h3 class="congratulations-heading"><span class="text-amazee-deep-sky-blue">Congratulations!</span>You are in!</h3>
                    <div>
                      <p><h4>Your free Drupal AI trial environment is configured and ready to explore.</h4></p>
                    </div>
                    <div>
                      <p><strong>Log in and discover</strong> what's possible with Drupal CMS and AI.</p>
                    </div>
                      <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href=${finalData.result_data.app_url} target='_blank'>Log In Now</a></p>
                      <div>
                      <p>
                      <h4>Your login details below:</h4>
                      <ul>
                        <li><b>Username:</b> ${finalData.result_data.app_admin_username}</li>
                        <li><b>Password:</b> ${finalData.result_data.app_admin_password}</li>
                        <li style="word-wrap: break-word;"><b>URL:</b> <a class="text-amazee-deep-sky-blue" target='_blank' href="${finalData.result_data.app_url}">${finalData.result_data.app_url}</a></li>
                      </ul>
                      </p>
                      </div>
                    <div>
                      <ul class="mb-6 bottom-callouts-ul">
                        <li class="check-inbox"><i></i><span><strong>Check your inbox</strong> for helpful insights into our AI services.</span></li>
                        <li class="need-help"><i></i>
                          <span><strong>Need some help?</strong> <br />
                          Our friendly support team is standing by at: <br />
                          <a class="text-amazee-deep-sky-blue font-semibold" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a></span>
                        </li>
                      </ul>
                      <h4>Welcome aboard!</h4>
                    </div>
                  `);
                } else if (finalStatus === "success" && finalData.result_data) {
                  console.log(finalData.result_data);
                  setResponseMessage(`
                    <h3 class="success-heading"><span class="text-amazee-deep-sky-blue">Success!!</span>You Are Registered!</h3>
                    <div>
                      <p><h4>You're on the waitlist!</h4></p>
                    </div>
                    <div>
                      <p>We’re moving fast to build something “amazeeng”and we’ll be ready in a few weeks. So, while you wait, feel free to explore our blogs to learn more about AI.</p>
                    </div>
                    <p><a class='btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto' href='/blog' target='_blank'>Read our Blogs</a></p>
                  `);
                } else {
                  setResponseMessage(`
                    <h3>OOPS - WE HIT A SNAG!</h3>
                    <p>We couldn't process your form submission. This is a temporary issue on our side, not yours.</p>
                    <p>Try refreshing your page to submit again, or email us at <a class="text-amazee-deep-sky-blue" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a> for assistance.</p>
                  `);
                }
              } else if (Date.now() - startTime >= timeout) {
                  setIsLoading(false);
                  setIsFormVisible(false);
                  setResponseMessage(`
                    <h3>OOPS - WE'RE EXPERIENCING A BRIEF DELAY</h3>
                    <p>Your trial environment is taking a bit longer than expected to set up. We're actively working on it!
                    Please check your email for access details, which should arrive shortly.</p>
                    <p>Haven't received anything? Contact us at <a class="text-amazee-deep-sky-blue" href="mailto:ai.support@amazee.io">ai.support@amazee.io</a> and we'll help you get started right away.</p>
                    `);
                } else {
                setTimeout(poll, pollInterval);
              }
            } catch (error) {
              console.error("Error during polling:", error);
              setIsLoading(false);
              setResponseMessage("An error occurred. Please try again later.");
            }
          };

          checkStatus();
        };

      poll();

      } else {
        setIsLoading(false);
        setResponseMessage(message);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setResponseMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      {isFormVisible ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <h3 className="text-2xl font-bold">Tell Us About Yourself</h3>
          <label className="w-full flex flex-col gap-y-3 mb-4">
            <p className="mb-0">First Name <span className="text-amazee-deep-sky-blue font-bold">*</span></p>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required className="border p-2 w-full mb-0" />
          </label>

          <label className="w-full flex flex-col gap-y-3 mb-4">
            <p className="mb-0">Last Name <span className="text-amazee-deep-sky-blue font-bold">*</span></p>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required className="border p-2 w-full mb-0" />
          </label>

          <label className="w-full flex flex-col gap-y-3 mb-4">
            <p className="mb-0">Work Email <span className="text-amazee-deep-sky-blue font-bold">*</span></p>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required className="border p-2 w-full mb-0" />
          </label>

          <label className="w-full flex flex-col gap-y-3 mb-4">
            <p className="mb-0">Data Region</p>
            <select name="dataRegion" value={formData.dataRegion} onChange={handleChange} required className="border p-2 w-full mb-0">
              <option value="" disabled>Select</option>
              <option value="5422d428-0bbf-46ce-a688-83d35936f4a9">Australia</option>
              <option value="4412071a-d7b5-4aef-8bde-beea6892b710">Europe (Germany)</option>
              {/* <option value="80979949-6ed5-4c0e-86f3-123b5bffae95">Switzerland</option> */}
              <option value="6e46ff17-301b-4444-8780-2990e1ddfa49">United States</option>
            </select>
          </label>

          {formData.dataRegion && (
        <label className="w-full flex flex-col gap-y-3 mb-4">
          <p className="mb-0">Experience</p>
          <select name="selectedApp" value={formData.selectedApp} onChange={handleChange} required className="border p-2 w-full">
            <option value="" disabled>Select</option>
            {appOptions[formData.dataRegion].map((app) => (
              <option key={app.value} value={app.value}>{app.name}</option>
            ))}
          </select>
        </label>
      )}

          <div className="mt-10">
            <label className="flex items-center check text-sm">
              <input type="checkbox" name="agreeToPolicy" checked={formData.agreeToPolicy === 1} onChange={handleChange} required className="mr-2 p-1" />
              <span>
              I agree to the
              <a className="text-amazee-deep-sky-blue inline-block mx-1" href="https://docs.amazee.io/policy/acceptable-use/" target="_blank"> Acceptable Use Policy</a> |
              <a className="text-amazee-deep-sky-blue inline-block mx-1" href="/privacy-policy" target="_blank"> Privacy Policy</a> | 
              <a className="text-amazee-deep-sky-blue inline-block mx-1" href="/trial-platform-terms-conditions" target="_blank">Terms of the Trial</a><span className="required-mark text-amazee-deep-sky-blue font-bold">*</span>
              </span>
            </label>
            <label className="flex items-center check text-sm">
              <input type="checkbox" name="agreeToMarketing" checked={formData.agreeToMarketing === 1} onChange={handleChange} className="mr-2 p-1" />
              <span>I agree to receive marketing and product updates from amazee.io</span>
            </label>
          </div>
          <div className="my-10 ai-recaptcha">
            <ReCAPTCHA
              sitekey="6Le8TuQqAAAAAG97G0-GB_6VcevfOdH4hs_HOldY"
              onChange={handleRecaptcha}
            />
          </div>
          <div className="flex justify-center">
            <button onClick={() => scrollTo('#contact-form')} disabled={isLoading}>
              <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto">
                {isLoading ? "Loading..." : "Sign Up Now"}
              </AmazeeIOButton>
            </button>
          </div>
        </form>
      ) : (
        
        <div className="mt-2" dangerouslySetInnerHTML={{ __html: responseMessage }} /> // Use dangerouslySetInnerHTML for special messages
      )}
    </>
  );
};

export default SignupForm;
